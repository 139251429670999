
import { IOpenApiHelpAdd } from '@/types';
import { Vue, Component } from 'vue-property-decorator';
import { applyOpenApiHelp } from '@/api/openApi';

@Component({
  name: 'OpenApiForm',
})
export default class extends Vue {
  created() {
    if (!window.opener) this.$router.push({ name: 'Index' });
  }

  private formData: IOpenApiHelpAdd = {
    companyName: '',
    tel: '',
    email: '',
    host: '',
    serverId: '',
    serverPassword: '',
  };

  private handleClickApply() {
    this.$validator.validate().then((valid: boolean) => {
      if (valid) {
        applyOpenApiHelp(this.formData).then(() => {
          alert('OpenAPI 활용신청 되었습니다.');
          window.close();
        });
      }
    });
  }
}
