import request from '@/utils/request';
import { IOpenApiHelpAdd } from '@/types';

const PATH = '/open-api';

export const getLastOpenApiHelp = () => request({
  url: `${PATH}/help/last-history`,
  method: 'get',
});

export const applyOpenApiHelp = (data: IOpenApiHelpAdd) => request({
  url: `${PATH}/help`,
  method: 'post',
  data,
});
